<script setup lang='ts'>
import { formatVideoTime, numFormat, timeFrom } from '@qcwp/utils'
import { ARTICLE_TYPE, articleAuthorPath, articleDetailPath, imageTransform } from '@qcwp/common'
import type { BaseArticleItem } from './hooks/item'
import { pxToRem } from '~~/src/common/theme/naive'
import type { ARTICLE_LIST_LOCATION } from '~~/src/store'
import { useJumpArticleStore } from '~~/src/store'

const props = withDefaults(defineProps<{
  article: BaseArticleItem | null
  location?: ARTICLE_LIST_LOCATION
  hiddenAuthor?: boolean
}>(), {
  article: null,
  hiddenAuthor: false,
})
const { isMobileOrTablet } = useDevice()

function clickHandle() {
  if (!props.article)
    return
  if (props.location)
    useJumpArticleStore().initHandle(props.location)
}
const router = useRouter()
function goPage() {
  if (!props.article)
    return
  const link = articleDetailPath(props.article.articleType, props.article)
  if ([ARTICLE_TYPE.LINK, ARTICLE_TYPE.LINK_VIDEO].includes(props.article.articleType))
    window.open(link, '_blank', 'noopener noreferrer')
  else
    router.push(link)
}
</script>

<template>
  <div v-if="article" flex md:flex-col h-full @click.capture="clickHandle">
    <div
      v-if="!isMobileOrTablet || article?.imgs?.[0]"
      class="relative border-1 border-gray-100 rounded-md" lt-md="mr-2 "
      :rel="article.articleType === ARTICLE_TYPE.LINK ? 'noopener noreferrer' : ''"
      @click.stop="goPage"
    >
      <base-proportion-img
        proportion="56.25%"
        class="flex-shrink-0 bg-gray-100 rounded-md overflow-hidden"
        md="w-full" lt-md="w-36"
        :img-props="{ class: 'object-cover md:img-hover', alt: article.title, lazy: true }"
        :src="imageTransform(article.imgs[0], { width: 288, height: 166, format: 'webp' })"
      />
      <span v-if="[ARTICLE_TYPE.VIDEO, ARTICLE_TYPE.LINK_VIDEO].includes(article.articleType) && article.payTime" class="video-time">
        <span>{{ formatVideoTime(article.payTime) }}</span>
      </span>
      <span
        v-if="[ARTICLE_TYPE.VIDEO, ARTICLE_TYPE.LINK_VIDEO].includes(article.articleType)"
        class="w-8 h-8 bg-black bg-opacity-50 text-white grid place-items-center rounded-full absolute top-50% left-50% -translate-50%"
        md="w-10 h-10"
      >
        <i class="i-fluent:play-28-filled text-4 md:text-5 mr-0.5" />
      </span>
    </div>
    <div flex="~ col grow" justify-between h-auto md="relative" :class="[hiddenAuthor ? 'md:pt-2' : 'md:pt-8']">
      <NuxtLink
        :target="[ARTICLE_TYPE.LINK, ARTICLE_TYPE.LINK_VIDEO].includes(article.articleType) ? '_blank' : '_self'"
        text-0
        :to="articleDetailPath(article.articleType, article)"
        :rel="[ARTICLE_TYPE.LINK, ARTICLE_TYPE.LINK_VIDEO].includes(article.articleType) ? 'noopener noreferrer' : ''"
        @click.stop
      >
        <n-ellipsis line-clamp="2" :tooltip="false" text-4 md:public-hover flex-grow>
          {{ article.title }}
        </n-ellipsis>
      </NuxtLink>
      <div class="flex items-end justify-between text-3 sm:text-3.5 text-title-600">
        <NuxtLink
          v-if="!hiddenAuthor"
          :to="articleAuthorPath(article.mediaUserId)"
          no-rel
          class="flex flex-grow w-0 items-end"
          md="absolute pl-2 public-hover -top-4 z-1 w-full"
          @click.stop
        >
          <utils-media-avatar
            :style="{ '--n-avatar-size-override': isMobileOrTablet ? `${pxToRem(30)}rem` : `${pxToRem(40)}rem` }"
            :src="imageTransform(article.mediaAvatar, { width: 100, height: 100, type: 'lfit', format: 'webp' })"
            :type="article.mediaType"
            :media-grade="article.mediaGrade"
            :name="article.mediaName"
            class="mr-2"
          />
          <n-ellipsis line-clamp="1" :tooltip="false">
            {{ article.mediaName }}
          </n-ellipsis>
        </NuxtLink>
        <NuxtLink
          no-rel
          :to="articleDetailPath(article.articleType, article)"
          flex="~ shrink-0"
          class="lt-md:ml-2 text-3 md:mt-1 overflow-hidden"
          @click.stop
        >
          <span :title="String(article.look)" flex-shrink-0 mr-2>
            {{ numFormat(article.look) }}{{ [ARTICLE_TYPE.VIDEO, ARTICLE_TYPE.LINK_VIDEO].includes(article.articleType) ? '播放' : '阅读' }}
          </span>

          <span v-if="article.commentTotal " :title="String(article.commentTotal)" flex-shrink-0 mr-2>
            {{ numFormat(article.commentTotal) }}评论
          </span>
          <span class="ssr-only">{{ article.showCreateTime }}</span>
          <ClientOnly>
            <span flex-shrink-0 :title="article.showCreateTime">
              {{ timeFrom(article.showCreateTime) }}
            </span>
          </ClientOnly>
          <!-- <span v-if="article.authorName" mx-1>作者：{{ article.authorName }}</span> -->
          <span v-if="article.sysUserName && article.sysUserName != '人工智能助手'" mx-2>编辑：{{ article.sysUserName }}</span>
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.video-time {
  --uno: flex items-center bg-black bg-opacity-80 text-white;
  --uno: rounded-sm z-2 absolute px-2  py-0.5 right-1.5 bottom-1.5 text-3  md-bottom-2 md-right-2;
}

.avatar {
  --n-avatar-size-override: 40px;
}

@screen lt-md {
  .avatar {
    --n-avatar-size-override: 30px;
  }
}
</style>
